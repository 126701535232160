import ListItem from "@material-ui/core/ListItem";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import * as React from "react";

import ListItemCheckbox from "~/components/core/ListItemCheckbox";
import ListItemRadio from "~/components/core/ListItemRadio";
import styled from "~/components/core/styled";
import { ALL_SUBJECTS } from "~/constants/filters";
import { ISubject } from "~/declarations/models/Subject";

import { getGroupIdentifier, subjectIsGroup } from "./hierarchyUtils";
import { SubjectStyledList } from "./styled";

const SubjectHierarchyItem: React.FC<{
  subject: ISubject;
  multiselect: boolean;
  partiallySelectedGroupsIdentifiers: string[];
  fullySelectedGroupsIdentifiers: string[];
  selectedSubjectsIds: number[];
  handleGroupClick: (subject: ISubject) => void;
  handleGroupSelect: (subject: ISubject) => void;
  handleSubjectSelect: (subject: ISubject) => void;
}> = ({
  subject,
  multiselect,
  partiallySelectedGroupsIdentifiers,
  fullySelectedGroupsIdentifiers,
  selectedSubjectsIds,
  handleGroupClick,
  handleGroupSelect,
  handleSubjectSelect
}) => {
  const isGroup = subjectIsGroup(subject);
  const SubjectListItem = multiselect ? ListItemCheckbox : ListItemRadio;

  const groupSelected =
    isGroup &&
    ((subject.name === ALL_SUBJECTS && !selectedSubjectsIds.length) ||
      partiallySelectedGroupsIdentifiers.includes(
        getGroupIdentifier(subject)
      ) ||
      fullySelectedGroupsIdentifiers.includes(getGroupIdentifier(subject)));
  const groupPartiallySelected =
    isGroup &&
    partiallySelectedGroupsIdentifiers.includes(getGroupIdentifier(subject));

  return (
    <SubjectListItem
      key={subject.name}
      onClick={
        isGroup
          ? () => handleGroupClick(subject)
          : () => handleSubjectSelect(subject)
      }
      onSelect={
        isGroup
          ? () => handleGroupSelect(subject)
          : () => handleSubjectSelect(subject)
      }
      selected={
        isGroup ? groupSelected : selectedSubjectsIds.includes(subject.id ?? 0)
      }
      action={isGroup && subject.name !== ALL_SUBJECTS && <ChevronRight />}
      divider
      dense
      {...(multiselect && isGroup
        ? { indeterminate: groupPartiallySelected }
        : {})}
    >
      {subject.name}
    </SubjectListItem>
  );
};

const SubjectHierarchyList: React.FC<{
  list: ISubject[];
  selectedSubjectsIds: number[];
  partiallySelectedGroupsIdentifiers: string[];
  fullySelectedGroupsIdentifiers: string[];
  multiselect: boolean;
  handleGroupClick: (subject: ISubject) => void;
  handleGroupSelect: (subject: ISubject) => void;
  handleSubjectSelect: (subject: ISubject) => void;
  onBack: () => void;
  backCaption?: string;
}> = ({
  list,
  selectedSubjectsIds,
  partiallySelectedGroupsIdentifiers,
  fullySelectedGroupsIdentifiers,
  multiselect,
  handleGroupClick,
  handleGroupSelect,
  handleSubjectSelect,
  onBack,
  backCaption
}) => (
  <SubjectStyledList>
    {backCaption && (
      <ListItem onClick={onBack} button divider dense>
        <BackIcon />
        {backCaption}
      </ListItem>
    )}
    {list.map(subject => (
      <SubjectHierarchyItem
        key={subject.name}
        subject={subject}
        fullySelectedGroupsIdentifiers={fullySelectedGroupsIdentifiers}
        partiallySelectedGroupsIdentifiers={partiallySelectedGroupsIdentifiers}
        selectedSubjectsIds={selectedSubjectsIds}
        multiselect={multiselect}
        handleGroupClick={handleGroupClick}
        handleGroupSelect={handleGroupSelect}
        handleSubjectSelect={handleSubjectSelect}
      />
    ))}
  </SubjectStyledList>
);

export default SubjectHierarchyList;

const BackIcon = styled(ChevronLeft)`
  margin-right: 10px;
`;
