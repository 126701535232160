import { ISubject } from "~/declarations/models/Subject";

export const subjectIsGroup = (subject: ISubject): boolean =>
  !subject.id || (subject.children ?? []).length > 0;

export const getGroupSubjects = (subject: ISubject): ISubject[] => {
  if (!subjectIsGroup(subject)) return [];
  return (subject.children ?? []).reduce<ISubject[]>(
    (res, subject) => [
      ...res,
      ...(subjectIsGroup(subject) ? getGroupSubjects(subject) : [subject])
    ],
    []
  );
};

export const getGroupIdentifier = (subject: ISubject): string =>
  `${subject.id}-${subject.name}`;

export const flattenGroups = (subjects: ISubject[]): ISubject[] =>
  subjects.reduce<ISubject[]>((res, subject) => {
    if (subjectIsGroup(subject)) {
      return [...res, subject, ...flattenGroups(subject.children ?? [])];
    }
    return res;
  }, []);

export const selectedSubjectsIdsToSelectedGroups = (
  selectedSubjectsIds: number[],
  allSubjects: ISubject[]
): [ISubject[], ISubject[]] =>
  flattenGroups(allSubjects).reduce<[ISubject[], ISubject[]]>(
    (res, subject) => {
      const [partiallySelectedGroups, fullySelectedGroups] = res;
      if (subjectIsGroup(subject)) {
        const groupSubjectsIds = getGroupSubjects(subject).map(s => s.id!);
        const selectedGroupSubjectsIds = groupSubjectsIds.filter(id =>
          selectedSubjectsIds.includes(id!)
        );
        if (selectedGroupSubjectsIds.length > 0) {
          if (selectedGroupSubjectsIds.length === groupSubjectsIds.length) {
            return [partiallySelectedGroups, [...fullySelectedGroups, subject]];
          } else {
            return [[...partiallySelectedGroups, subject], fullySelectedGroups];
          }
        }
      }
      return res;
    },
    [[], []]
  );

export const getSelectorState = (
  allSubjects: ISubject[],
  selectedSubjects: ISubject[]
) => {
  const selectedSubjectsIds = selectedSubjects.reduce<number[]>(
    (res, s) => (s.id ? [...res, s.id] : res),
    []
  );
  const [
    partiallySelectedGroups,
    fullySelectedGroups
  ] = selectedSubjectsIdsToSelectedGroups(selectedSubjectsIds, allSubjects);
  const partiallySelectedGroupsIdentifiers = partiallySelectedGroups.map(
    getGroupIdentifier
  );
  const fullySelectedGroupsIdentifiers = fullySelectedGroups.map(
    getGroupIdentifier
  );
  return {
    selectedSubjectsIds,
    partiallySelectedGroupsIdentifiers,
    fullySelectedGroupsIdentifiers
  };
};
