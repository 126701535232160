import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import * as React from "react";

import ListItem from "~/components/core/ListItem";

interface Props {
  children: React.ReactNode;
  action?: React.ReactNode;
  onClick?: () => void;
  onSelect?: () => void;
  selected?: boolean;
  indeterminate?: boolean;
  dense?: boolean;
  divider?: boolean;
  checkboxProps?: CheckboxProps;
}

const ListItemCheckbox: React.FC<Props> = ({
  children,
  action,
  onClick,
  onSelect,
  selected,
  indeterminate,
  dense,
  divider,
  checkboxProps
}: Props) => {
  const handleSelect = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    onSelect && onSelect();
  };

  return (
    <ListItem
      onClick={onClick}
      action={action}
      icon={
        <Checkbox
          color="primary"
          {...checkboxProps}
          checked={selected}
          indeterminate={indeterminate}
          onClick={handleSelect}
        />
      }
      dense={dense}
      divider={divider}
    >
      {children}
    </ListItem>
  );
};

export default ListItemCheckbox;
