import Radio, { RadioProps } from "@material-ui/core/Radio";
import * as React from "react";

import ListItem from "~/components/core/ListItem";

interface Props {
  children: React.ReactNode;
  action?: React.ReactNode;
  onClick?: () => void;
  selected?: boolean;
  divider?: boolean;
  dense?: boolean;
  radioProps?: RadioProps;
}

const ListItemCheckbox: React.FC<Props> = ({
  children,
  action,
  onClick,
  selected,
  radioProps,
  divider,
  dense
}: Props) => (
  <ListItem
    onClick={onClick}
    action={action}
    icon={<Radio color="primary" {...radioProps} checked={selected} />}
    divider={divider}
    dense={dense}
  >
    {children}
  </ListItem>
);

export default ListItemCheckbox;
