import {
  default as MuiListItem,
  ListItemProps
} from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import * as React from "react";

import styled from "~/components/core/styled";

interface Props extends ListItemProps {
  icon?: React.ReactElement;
  action?: React.ReactNode;
  onClick?: () => void;
}

const ListItem: React.FC<Props> = ({
  children,
  icon,
  action,
  onClick,
  dense,
  divider
}: Props) => (
  <MuiListItem onClick={onClick} dense={dense} divider={divider} button>
    {icon && <ListItemIcon>{icon}</ListItemIcon>}
    <ListItemText>{children}</ListItemText>
    {action && <ActionWrapper>{action}</ActionWrapper>}
  </MuiListItem>
);

export default ListItem;

const ActionWrapper = styled.span`
  display: flex;
  position: absolute;
  right: 10px;
`;
