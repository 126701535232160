import List from "@material-ui/core/List";

import styled from "~/components/core/styled";

export const SubjectStyledList = styled(List)`
  padding: 0;

  .MuiListItem-root {
    text-transform: uppercase;
    padding: 2px 35px 2px 7px;
    min-height: 39px;

    .MuiListItemIcon-root {
      min-width: unset;
    }

    .MuiButtonBase-root {
      padding: 5px;
      margin-right: 5px;
    }
  }

  .MuiListItemSecondaryAction-root {
    right: 5px;
  }
`;
