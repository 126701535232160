import * as React from "react";

import { SubjectGroup } from "~/declarations/models/Subject";
import { isEmpty, isEquals } from "~/utils/common";
import useDebounce from "~/utils/useDebounce";

export function useSearchFilter(
  fieldValue: string,
  inputTouched: boolean,
  subjects: SubjectGroup[]
): SubjectGroup[] {
  const [subjectsToSelect, setSubjectToSelect] = React.useState<SubjectGroup[]>(
    subjects
  );
  const debounceFilter = useDebounce<string>(fieldValue, 500);

  React.useEffect(() => {
    if (debounceFilter && inputTouched) {
      const filteredSubjects: SubjectGroup[] = subjects
        .slice()
        .reduce(
          (result: SubjectGroup[], subject: SubjectGroup): SubjectGroup[] => {
            const { name, children } = subject;

            if (name.toLowerCase().includes(debounceFilter.toLowerCase())) {
              result.push(subject);
              return result;
            }

            if (!isEmpty(children)) {
              const filteredChildren = children?.filter(({ name }) =>
                name.toLowerCase().includes(debounceFilter.toLowerCase())
              );

              if (!isEmpty(filteredChildren)) {
                subject.children = filteredChildren;
                result.push(subject);
              }
            }

            return result;
          },
          []
        );

      setSubjectToSelect(filteredSubjects || []);
    }
  }, [debounceFilter, inputTouched, subjects]);

  React.useEffect(() => {
    if (!debounceFilter && !isEquals(subjectsToSelect, subjects)) {
      setSubjectToSelect(subjects);
    }
  }, [debounceFilter, subjects, subjectsToSelect]);

  return subjectsToSelect;
}
